.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.homebody{
  
  background-color: #282c34;
  height: 100%;
  width: 100%;
  margin-top: 70px;
  
}

.login-page{

  position: relative;
  /* top: 35%; */
  /* left: 40%; */
  margin-top: 10%;
  padding: 20px;
  border: 5px;
  margin-left: 40%;
  margin-right: 40%;
  width: 20%;
  /* border-color: rgb(245, 243, 239); */
  border-width: 2px;
  /* background-color: rgb(142, 101, 58); */
  border-radius: 8px;
  /* width: 400px; */
  height: 300px;
  /* text-align: center; */
  border-style: groove;
  border-color: white;
  
}

.satin-all-page{

  position: relative;
  /* top: 35%; */
  /* left: 40%; */
  margin-top: 5%;
  padding: 20px;
  border: 5px;
  margin-left: 35%;
  margin-right: 35%;
  width: 30%;
  /* padding: 10; */
  border-width: 2px;
  /* background-color: rgb(142, 101, 58); */
  border-radius: 8px;
  /* width: 400px; */
  /* height: 300px; */
  /* text-align: center; */
  border-style: groove;
  border-color: white;
  background-color: #868684;
  
  
}

.satin-al-inner-block{
  /* background-color: rgb(61, 50, 26); */
  background-color: #282c34;
  border-radius: 8px;
  margin: 0px;

}

.yorumlarim{

  text-align: left;
  border-style: groove;
  border-color: rgb(120, 109, 185);
  /* border-width: px; */
  border-radius: 8px;
  border: 5px;
  color: #fc9803;  
  background-color: #331c0836;
  padding: 8px; 

}

.feno-yorumlarim{
  text-align: left;
  border-style: groove;
  border-color: rgb(120, 109, 185);
  /* border-width: px; */
  border-radius: 8px;
  border: 5px;
  color: #fc9803;  
  background-color: #331c0836;
  padding: 8px;
  height: 80px;
  overflow-y: auto;
}

.register{

  position: relative;
  /* top: 35%; */
  /* left: 40%; */
  margin-top: 10%;
  padding: 20px;
  border: 5px;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  /* border-color: rgb(245, 243, 239); */
  border-width: 2px;
  /* background-color: rgb(142, 101, 58); */
  border-radius: 8px;
  /* width: 400px; */
  /* height: 300px; */
  /* width: ; */
  text-align: center;
  border-style: groove;
  border-color: white;

}

/* Start of mobile view */
@media only screen and (max-width: 575px) {
  .login-page{
    margin-top: 30%;

    position: relative;
    
    width: 55%;
    
    padding: 20px;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    text-align: center;
  }

  .register{
    margin-top: 20%;

    position: relative;
    
    width: 55%;
    
    padding: 20px;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    text-align: center;
  }

  .satin-all-page{
    margin-top: 30%;

    position: relative;
    
    width: 90%;
    
    padding: 20px;
    margin-left: 5%;
    margin-right: 5%;
    /* width: 70%; */
    text-align: center;
  }

} 
/* End of mobile view */

 /* .inf-content{
    border:1px solid #DDDDDD;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:10px;
    box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.3);
}	 */

table {
  /* width: 500px;
  border-width: 8px;
  border-color: white;
  border-style: solid; */
  /* border-radius: 10px !important; */
}
table tbody tr:last-child {
  border-bottom: 0px;
  border-bottom: 0px;
  
}
.footer-text{
  color: blue;
  text-decoration: none;
  cursor: pointer;
  
}

.satin-al-details{
  color: #65c7e0;
  text-decoration: none;
  cursor: pointer;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  /* line-height: 100px; */
  position: fixed;
  /* background: rgba(28, 32, 36, 1); */
  bottom:0px;
  /* width: 100%; */
  margin-left: 0;
  margin-right: 0;
  /* height: 100px; */
  text-align: center;
 }
